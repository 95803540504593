import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Typography, styled } from '@mui/material';
import React from 'react';
import PLMUpdateModal from './PLMUpdateModal';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function ModalUpdate() {

  const [opened, setOpened] = React.useState(true);

  const handleClosed = () => {
    setOpened(false);
  };
 return (
//   <Modal open={true}>
//       <BootstrapDialog
//         onClose={handleClosed}
//         aria-labelledby="customized-dialog-title"
//         open={opened}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
//           Modal title
//         </DialogTitle>
//         <IconButton
//           aria-label="close"
//           onClick={handleClosed}
//           sx={(theme) => ({
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: theme.palette.grey[500],
//           })}
//         >
//           <CloseIcon />
//         </IconButton>
//       </BootstrapDialog>
//     <Dialog
//        onClose={handleClosed}
//        open={opened}
//        style={{
//          position: "absolute",
//          height: 500,
//          width: 700,
//          margin: "auto",
//          padding: "2%",
//          color: "white",
//        }}
//      >
//       <>
//         <PLMUpdateModal />
//       </>
//   </Dialog>
// </Modal>
<React.Fragment>
<BootstrapDialog
  onClose={handleClosed}
  aria-labelledby="customized-dialog-title"
  open={opened}
  style={{width:'100vw'}}
  
>
  <DialogTitle sx={{ m: 0, p: 3 }} id="customized-dialog-title">
    ANNOUNCEMENT
  </DialogTitle>
  <IconButton
    aria-label="close"
    onClick={handleClosed}
    sx={(theme) => ({
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    })}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent dividers>
    <PLMUpdateModal />
  </DialogContent>
</BootstrapDialog>
</React.Fragment>
 )
}
export default ModalUpdate;